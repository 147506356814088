import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LogInIcon, LogOutIcon, UserIcon, UserPlusIcon } from "lucide-react";
import { useTranslations } from "@/i18n";
import type { CatalogLocals } from "@/types";
import { useAccount } from "@/hooks/use-account";
import { useUser } from "@/hooks/use-user";
import { useCredit } from "@/hooks/use-credit";
import { getNavItems } from "../dashboard/nav-items";
import { logout } from "./auth.functions";

export const AuthMenu = ({ locals }: { locals: CatalogLocals }) => {
  const { user } = useUser();
  const { account } = useAccount();
  const t = useTranslations(locals.lang);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="iconSm"
          className="overflow-hidden"
          aria-label={t("auth.menu")}
        >
          {user ? (
            (account?.displayName ?? user.displayName)
              ?.split(" ")
              .slice(0, 2)
              .map((n) => n.at(0)?.toUpperCase())
              .join("") ?? "??"
          ) : (
            <UserIcon />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <AuthMenuContent locals={locals} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const AuthMenuContent = ({ locals }: { locals: CatalogLocals }) => {
  const { lang, supplier } = locals;
  const t = useTranslations(locals.lang);
  const { user } = useUser();
  const { credit } = useCredit();
  const { account } = useAccount();

  const items = getNavItems({ lang, hasCredit: !!credit, showCoins: supplier.country !== "US" });

  return user ? (
    <>
      <DropdownMenuLabel>{account?.displayName ?? user.displayName ?? "??"}</DropdownMenuLabel>
      <DropdownMenuSeparator />
      {items.map((item) => (
        <DropdownMenuItem key={item.href} asChild>
          <a href={`/${lang}/dashboard${item.href}`} className="flex items-center gap-1">
            <item.icon className="w-4 h-4" />
            {item.title}
          </a>
        </DropdownMenuItem>
      ))}
      <DropdownMenuSeparator />
      <DropdownMenuItem onClick={logout} className="flex items-center gap-1">
        <LogOutIcon className="w-4 h-4" />
        {t("auth.signOut")}
      </DropdownMenuItem>
    </>
  ) : (
    <>
      <DropdownMenuItem asChild>
        <a
          href={`/${lang}/login?redirectTo=${location.pathname}`}
          className="flex items-center gap-1"
        >
          <LogInIcon className="w-4 h-4" />
          {t("auth.signIn")}
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a
          href={`/${lang}/signup?redirectTo=${location.pathname}`}
          className="flex items-center gap-1"
        >
          <UserPlusIcon className="w-4 h-4" />
          {t("auth.signUp")}
        </a>
      </DropdownMenuItem>
    </>
  );
};
